<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
h1,h2,h3,h4,h5,h6 {
  font-family: 'Roboto', sans-serif;

}
#app {
  font-family: 'Open Sans', sans-serif;
}

</style>
